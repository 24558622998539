import { get, post, utf8ToB64, b64ToUtf8 } from '@/utils/api';

const { VUE_APP_LANGUAGES_LIST } = process.env;
const languageList = JSON.parse(VUE_APP_LANGUAGES_LIST);

export default {
  state: () => ({
    visitorAuthorized: false,
    isVisitorAuthoring: false,
    visitorUser: null,
    visitorUserPhoneNumber: null,
    visitorSid: null,
    visitorVerifyCode: null,
    visitorErrorMessage: null,
    bookingInformation: null,
    questionAnswer: null,
    // isVisitorLoginState: false,
  }),
  getters: {
    IS_VISITOR_AUTHORIZED: (state) => state.visitorAuthorized,
    GET_VISITOR_ERROR_MESSAGE: (state) => state.visitorErrorMessage,
    GET_IS_VISITOR_AUTHORING: (state) => state.isVisitorAuthoring,
    GET_VISITOR_SID: (state) => state.visitorSid,
    GET_VISITOR_USER: (state) => state.visitorUser,
    GET_BOOKING_INFORMATION: (state) => state.bookingInformation,
    GET_QUESTION_ANSWER: (state) => state.questionAnswer,
    // GET_VISITOR_LOGIN_STATE: (state) => state.isVisitorLoginState,
  },
  mutations: {
    SET_VISITOR_AUTHORIZED: (state, payload) => {
      state.visitorAuthorized = payload;
    },
    SET_VISITOR_ERROR_MESSAGE: (state, payload) => {
      state.visitorErrorMessage = payload;
    },
    SET_VISITOR_PHONE_NUMBER: (state, payload) => {
      state.visitorUserPhoneNumber = payload;
    },
    SET_VISITOR_SID: (state, payload) => {
      state.visitorSid = payload;
    },
    SET_VISITOR_USER: (state, payload) => {
      state.visitorUser = payload;
    },
    SET_VISITOR_VERIFY_CODE: (state, payload) => {
      state.visitorUserVerifyCode = payload;
    },
    SET_IS_VISITOR_AUTHORING: (state, payload) => {
      state.isVisitorAuthoring = payload;
    },
    SET_BOOKING_INFORMATION: (state, payload) => {
      state.bookingInformation = payload.result;
    },
    SET_QUESTION_ANSWER: (state, payload) => {
      state.questionAnswer = payload;
    },
    // SET_VISITOR_LOGIN_STATE: (state, payload) => {
    //   state.isVisitorLoginState = payload;
    // },
  },
  actions: {
    REQUEST_VISITOR_LOGIN: async (context, payload) => {
      const data = new FormData();
      data.append('pnr', payload);

      context.commit('SET_IS_VISITOR_AUTHORING', true);
      const requestUrl = '/visitor/requestLogin';

      try {
        const response = await post('BFF', requestUrl, data, context, '', true);
        if (response.data.error) {
          context.commit('SET_VISITOR_ERROR_MESSAGE', response.data.error.message);
        } else {
          context.commit('SET_VISITOR_ERROR_MESSAGE', null);
          context.commit('SET_VISITOR_SID', response.data.data.visitorSid);
        }
      } catch (error) {
        context.commit('SET_VISITOR_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_VISITOR_AUTHORING', false);
    },
    REQUEST_VISITOR_BY_VERIFY_CODE: async (context) => {
      const data = new FormData();
      const { visitorUserVerifyCode } = context.state;
      data.append('verificationCode', visitorUserVerifyCode);
      context.commit('SET_IS_VISITOR_AUTHORING', true);
      try {
        const response = await post('BFF', '/visitor/verifyCode', data, context, '', true);
        if (response.data.error) {
          context.commit('SET_VISITOR_ERROR_MESSAGE', response.data.error.message);
          context.commit('SET_VISITOR_SID', null);
        } else {
          // context.commit('SET_VISITOR_LOGIN_STATE', false);
          context.commit('SET_VISITOR_ERROR_MESSAGE', null);
          context.commit('SET_VISITOR_AUTHORIZED', true);
          context.commit('SET_VISITOR_USER', response.data);

          window.sessionStorage.setItem('visitorSid', context.state.visitorSid);
          window.sessionStorage.setItem('visitorLogin', true);
          window.sessionStorage.setItem('visitorUser', utf8ToB64(JSON.stringify(response.data)));
        }
      } catch (error) {
        context.commit('SET_VISITOR_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_VISITOR_AUTHORING', false);
    },
    REQUEST_VISITOR_LOGOUT: async (context) => {
      context.commit('SET_IS_VISITOR_AUTHORING', true);
      const requestUrl = '/visitor/logout';

      try {
        const response = await post('BFF', requestUrl, null, context);
        if (response.data.error) {
          context.commit('SET_VISITOR_ERROR_MESSAGE', response.data.error.message);
        } else {
          context.commit('SET_VISITOR_ERROR_MESSAGE', null);
          context.commit('SET_VISITOR_AUTHORIZED', false);
          context.commit('SET_VISITOR_SID', null);
          context.commit('SET_VISITOR_USER', null);

          // context.commit('SET_VISITOR_LOGIN_STATE', true);

          window.sessionStorage.removeItem('visitorSid');
          window.sessionStorage.removeItem('visitorLogin');
          window.sessionStorage.removeItem('visitorUser');
        }
      } catch (error) {
        context.commit('SET_VISITOR_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_VISITOR_AUTHORING', false);
    },
    VERIFY_VISITOR_LOGIN_STATE: async (context) => {
      const visitorSid = window.sessionStorage.getItem('visitorSid');
      const requestUrl = '/visitor/checkSession';

      if (visitorSid) {
        await context.commit('SET_VISITOR_SID', visitorSid);
        try {
          const response = await get('BFF', requestUrl, context);

          if (response.data.error) {
            context.commit('SET_VISITOR_ERROR_MESSAGE', response.data.error.message);
          } else if (response.data.data.logined) {
            // context.commit('SET_VISITOR_LOGIN_STATE', false);
            context.commit('SET_ODYSSEY_AGENT_CLERK_NAME', response.data.data.clerkName);
            context.commit('SET_VISITOR_AUTHORIZED', true);
            context.commit('SET_VISITOR_USER', JSON.parse(b64ToUtf8(window.sessionStorage.getItem('visitorUser'))));
          }
        } catch (error) {
          context.commit('SET_VISITOR_ERROR_MESSAGE', null);
          context.commit('SET_VISITOR_AUTHORIZED', false);
          context.commit('SET_VISITOR_SID', null);
          context.commit('SET_VISITOR_USER', null);

          window.sessionStorage.removeItem('visitorSid');
          window.sessionStorage.removeItem('agencyLogin');
          window.sessionStorage.removeItem('visitorUser');
          // context.commit('SET_VISITOR_LOGIN_STATE', true);
        }
      }
    },
    FETCH_BOOKING_INFORMATION: async (context) => {
      const visitorSid = window.sessionStorage.getItem('visitorSid');
      const requestUrl = '/operation/bookInfo';

      if (visitorSid) {
        try {
          const response = await get('BFF', requestUrl, context);

          if (response.data.error) {
            context.commit('SET_VISITOR_ERROR_MESSAGE', response.data.error.message);
          } else {
            context.commit('SET_BOOKING_INFORMATION', response.data);
          }
        } catch (error) {
          // context.commit('SET_VISITOR_ERROR_MESSAGE', null);
          // context.commit('SET_VISITOR_AUTHORIZED', false);
          // context.commit('SET_VISITOR_SID', null);
          // context.commit('SET_VISITOR_USER', null);

          // window.sessionStorage.removeItem('visitorSid');
          // window.sessionStorage.removeItem('agencyLogin');
          // window.sessionStorage.removeItem('visitorUser');
          // context.commit('SET_VISITOR_LOGIN_STATE', true);
        }
      }
    },
    FETCH_QUESTION_ANSWER: async (context) => {
      const visitorSid = window.sessionStorage.getItem('visitorSid');
      const requestUrl = '/questionsAnswers?from=1&to=1000';

      if (visitorSid) {
        try {
          const response = await get('BFF', requestUrl, context);
          if (response.data.error) {
            context.commit('SET_VISITOR_ERROR_MESSAGE', response.data.error.message);
          } else {
            context.commit('SET_QUESTION_ANSWER', response.data.data);
          }
        } catch (error) {
          /* eslint no-console: 0 */
          console.log(error);
        }
      }
    },
    REQUEST_ACCEPTED_STATE_FOR_CHANGED_HOTEL: async (context, payload) => {
      const visitorSid = window.sessionStorage.getItem('visitorSid');
      const { acceptanceToken, accepted } = payload;

      if (visitorSid) {
        try {
          const response = await get('BFF', `/operation/acceptHotelChange/${acceptanceToken}?accepted=${accepted}&langCode=${languageList[context.rootState.language]}'`, context);
          if (response.data.error) {
            return response.data.error.message;
          } else {
            return response.data.data;
          }
        } catch (error) {
          /* eslint no-console: 0 */
          console.log(error);
        }
      }
      return '';
    },
  },
};
